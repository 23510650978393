<template>
  <div>
    <b-row>
      <b-col>
        <!-- -->
        <b-row>
          <b-col>
            <div style="margin-top: 39px">
              <p>This is used for language translation only</p>
              <p>
                <strong>Note:</strong> Changing the context of the question or leaving a translation empty <strong>does not</strong> change the logic of the questionnaire
              </p>
            </div>
          </b-col>
        </b-row>
        <div style="height: 52px"></div>
        <!-- -->
        <b-row>
          <b-col>
            <!--- --->
            <b-table-simple
              v-if="questionnaire && questionnaire.length > 0"
              id="table-1"
              hover
              small
              caption-top
              responsive
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th
                    v-for="data in header"
                    :key="data.title"
                    :class="isEditContent"
                    :style="data.style"
                  >
                    <div>
                      {{ data.title }}
                      <p v-if="data.desc" class="mb-0 titleDesc">
                        {{ data.desc }}
                      </p>
                    </div>
                  </b-th>
                </b-tr>
              </b-thead>
              <!-------------------------------- body ------------------------------------ -->
              <b-tbody>
                <!---------------------------------------intro---------------------------- -->
                <b-tr :key="questionnaire[0].id.data + 'tr42342'">
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[0].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td />
                  <b-td>
                    <Editor
                      :key="questionnaire[0].question.data.isEdit + 'asdasd'"
                      v-model="questionnaire[0].question.data.editData"
                      :is-edit="questionnaire[0].question.data.isEdit"
                      :current-data="questionnaire[0].question.data.data"
                      :validate-max-value="244"
                    />
                    <Editor
                      :key="
                        questionnaire[0].question.data1.isEdit + '21sqwq212'
                      "
                      v-model="questionnaire[0].question.data1.editData"
                      :is-edit="questionnaire[0].question.data1.isEdit"
                      :current-data="questionnaire[0].question.data1.data"
                      :validate-max-value="74"
                    />
                    <Editor
                      :key="
                        questionnaire[0].question.data2.isEdit + '21sqwq213'
                      "
                      v-model="questionnaire[0].question.data2.editData"
                      :is-edit="questionnaire[0].question.data2.isEdit"
                      :current-data="questionnaire[0].question.data2.data"
                      :validate-max-value="18"
                    />
                  </b-td>
                  <b-td />
                  <b-td />
                </b-tr>
                <!--------------------------------------- Privacy------------------------------->
                <b-tr :key="questionnaire[1].id.data + '23rwetr'">
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[1].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td>
                    <div v-if="questionnaire[1].shortText.data.data !== ''">
                      <Editor
                        :key="questionnaire[1].shortText.data.isEdit + '212121'"
                        v-model="questionnaire[1].shortText.data.editData"
                        :is-edit="questionnaire[1].shortText.data.isEdit"
                        :current-data="questionnaire[1].shortText.data.data"
                      />
                    </div>
                  </b-td>
                  <b-td>
                    <Editor
                      :key="questionnaire[1].question.data.isEdit + '2a1212'"
                      v-model="questionnaire[1].question.data.editData"
                      :is-edit="questionnaire[1].question.data.isEdit"
                      :current-data="questionnaire[1].question.data.data"
                      :validate-max-value="89"
                    />
                    <Editor
                      :key="questionnaire[1].question.data1.isEdit + '21v212'"
                      v-model="questionnaire[1].question.data1.editData"
                      :is-edit="questionnaire[1].question.data1.isEdit"
                      :current-data="questionnaire[1].question.data1.data"
                      :validate-max-value="44"
                    />
                  </b-td>
                  <b-td />
                  <b-td>
                    <b-row>
                      <b-col>
                        <!--- -->
                        <b-row>
                          <b-col cols="4">
                            <h5>Mandatory</h5>
                          </b-col>
                          <b-col>
                            <check-box-editor
                              v-model="questionnaire[1].options.data.editData"
                              :is-edit="questionnaire[1].options.data.isEdit"
                              @transfer="transfer($event)"
                            />
                          </b-col>
                        </b-row>
                        <!--- -->
                        <b-row>
                          <b-col cols="2">
                            <h5>URL</h5>
                          </b-col>
                          <b-col>
                            <Editor
                              :key="questionnaire[1].options.data1.isEdit +'21dds264512'"
                              v-model="questionnaire[1].options.data1.editData"
                              :type="linkType"
                              :is-edit="questionnaire[1].options.data1.isEdit"
                              :current-data="
                                questionnaire[1].options.data1.data
                              "
                            />
                          </b-col>
                        </b-row>
                        <!--- -->
                      </b-col>
                    </b-row>
                    <!--- -->
                  </b-td>
                </b-tr>
                
                <!---------------------------------------- Emailer ------------------------------>
                <b-tr :key="questionnaire[2].id.data + 't24234r'">
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[2].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td>
                    <div v-if="questionnaire[2].shortText.data.data !== ''">
                      <Editor
                        :key="questionnaire[2].shortText.data.isEdit +'2fsdfsdf1212'
                        "
                        v-model="questionnaire[2].shortText.data.editData"
                        :is-edit="questionnaire[2].shortText.data.isEdit"
                        :current-data="questionnaire[2].shortText.data.data"
                      />
                    </div>
                  </b-td>
                  <b-td>
                    <Editor
                      :key="
                        questionnaire[2].question.data.isEdit + '2fdfsdf1212'
                      "
                      v-model="questionnaire[2].question.data.editData"
                      :is-edit="questionnaire[2].question.data.isEdit"
                      :current-data="questionnaire[2].question.data.data"
                      :validate-max-value="125"
                    />
                  </b-td>
                  <b-td />
                  <b-td> </b-td>
                </b-tr>
                <!--------------------------------------Button---------------------------- --->
                <b-tr :key="questionnaire[3].id.data + 'tr423423'">
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[3].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td>
                    <div v-if="questionnaire[3].shortText.data.data !== ''">
                      <Editor
                        :key="
                          questionnaire[3].shortText.data.isEdit + '21bbbb212'
                        "
                        v-model="questionnaire[3].shortText.data.editData"
                        :is-edit="questionnaire[3].shortText.data.isEdit"
                        :current-data="questionnaire[3].shortText.data.data"
                      />
                    </div>
                  </b-td>
                  <b-td>
                    <Editor
                      :key="questionnaire[3].question.data.isEdit + '2gt1212'"
                      v-model="questionnaire[3].question.data.editData"
                      :is-edit="questionnaire[3].question.data.isEdit"
                      :current-data="questionnaire[3].question.data.data"
                      :validate-max-value="35"
                    />
                  </b-td>
                  <b-td />
                  <b-td />
                </b-tr>
                <!---------------------------------------- Question 1 ------------------------------>
                <b-tr :key="questionnaire[4].id.data + 'tr5345te'">
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[4].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td>
                    <div v-if="questionnaire[4].shortText.data.data !== ''">
                      <Editor
                        :key="
                          questionnaire[4].shortText.data.isEdit + '221211212'
                        "
                        v-model="questionnaire[4].shortText.data.editData"
                        :is-edit="questionnaire[4].shortText.data.isEdit"
                        :current-data="questionnaire[4].shortText.data.data"
                        :validate-max-value="124"
                      />
                    </div>
                  </b-td>
                  <b-td>
                    <Editor
                      :key="questionnaire[4].question.data.isEdit + '2d112212'"
                      v-model="questionnaire[4].question.data.editData"
                      :is-edit="questionnaire[4].question.data.isEdit"
                      :current-data="questionnaire[4].question.data.data"
                    />
                    <Editor
                      :key="questionnaire[4].question.data1.isEdit + '2d112213'"
                      v-model="questionnaire[4].question.data1.editData"
                      :is-edit="questionnaire[4].question.data1.isEdit"
                      :current-data="questionnaire[4].question.data1.data"
                    />
                  </b-td>
                  <b-td />
                  <b-td />
                </b-tr>
                <!--------------------------------------- Question 2---------------------------- -->
                <b-tr :key="questionnaire[5].id.data + 'tr424ter'">
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[5].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td>
                    <div v-if="questionnaire[5].shortText.data.data !== ''">
                      <Editor
                        :key="
                          questionnaire[5].shortText.data.isEdit + '2d1d12212'
                        "
                        v-model="questionnaire[5].shortText.data.editData"
                        :is-edit="questionnaire[5].shortText.data.isEdit"
                        :current-data="questionnaire[5].shortText.data.data"
                      />
                    </div>
                  </b-td>
                  <b-td>
                    <Editor
                      :key="
                        questionnaire[5].question.data.isEdit + '2d1d12f212'
                      "
                      v-model="questionnaire[5].question.data.editData"
                      :is-edit="questionnaire[5].question.data.isEdit"
                      :current-data="questionnaire[5].question.data.data"
                      :validate-max-value="124"
                    />
                  </b-td>
                  <b-td />
                  <b-td />
                </b-tr>
                <!----------------------------------------  Question 3 ------------------------------>
                <b-tr
                  v-for="(value, key, index) in questionnaire[6].options"
                  :key="questionnaire[6].id.data + index + '23data152tr'"
                >
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[6].maxRowLength.data"
                  >
                    <div>
                      <h5 class="color">
                        {{ questionnaire[6].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[6].maxRowLength.data"
                  >
                    <Editor
                      :key="
                        questionnaire[6].shortText.data.isEdit + '2d1ddata112f212'
                      "
                      v-model="questionnaire[6].shortText.data.editData"
                      :is-edit="questionnaire[6].shortText.data.isEdit"
                      :current-data="questionnaire[6].shortText.data.data"
                      :validate-max-value="26"
                    />
                  </b-td>
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[6].maxRowLength.data"
                  >
                    <Editor
                      :key="
                        questionnaire[6].question.data.isEdit + '2d1dsddata112f212'
                      "
                      v-model="questionnaire[6].question.data.editData"
                      :is-edit="questionnaire[6].question.data.isEdit"
                      :current-data="questionnaire[6].question.data.data"
                      :validate-max-value="124"
                    />
                  </b-td>
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[6].maxRowLength.data"
                  >
                    <Editor
                      :key="
                        questionnaire[6].description.data.isEdit + '2d1d12fdata1212'
                      "
                      v-model="questionnaire[6].description.data.editData"
                      :is-edit="questionnaire[6].description.data.isEdit"
                      :current-data="questionnaire[6].description.data.data"
                      :validate-max-value="45"
                    />
                  </b-td>
                  <b-td v-if="index !== 6">
                    <Editor
                      v-if="index === 5"
                      :key="value.isEdit + index + '2d1gd12f2data112'"
                      v-model="value.editData"
                      :is-edit="value.isEdit"
                      :current-data="value.data"
                      :validate-max-value="15"
                    />
                    <EditorQn3
                      v-else
                      :key="value.isEdit + index + '2d1gd12f2data112'"
                      v-model="value.editData"
                      :is-edit="value.isEdit"
                      :current-data="value.data"
                      :validate-max-value="100"
                    />
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(value, key, index) in questionnaire[6].shortName"
                  :key="questionnaire[6].id.data + index + '2352tr11data2'"
                >
                  <b-td style="border: none"></b-td>
                  <b-td style="border: none"></b-td>
                  <b-td>
                    <Editor
                      :key="
                        questionnaire[6].question.data1.isEdit + '2d1dsd12f2121data2'
                      "
                      v-model="questionnaire[6].question.data1.editData"
                      :is-edit="questionnaire[6].question.data1.isEdit"
                      :current-data="questionnaire[6].question.data1.data"
                      :validate-max-value="230"
                    />
                  </b-td>
                  <b-td style="border: none"></b-td>
                  <b-td>
                    <template v-if="!questionnaire[6].question.data1.isEdit">
                      <div
                        :id="`popover-image-${questionaireImageType}-6-${index}`"
                        class="icon-img"
                      >
                        <img
                          width="30px"
                          height="30px"
                          :src="IMAGE_API_PATH + questionnaire[6].options.data6.data"
                          fluid
                          alt="Responsive image"
                        />
                      </div>
                      <ImagePopover
                        :product-type="`${questionaireImageType}-6`"
                        :current-row-index="index"
                        :current-name="questionnaire[6].options.data6.data"
                        :current-image="IMAGE_API_PATH + questionnaire[6].options.data6.data"
                      />
                    </template>
                    <template v-else>
                      <img
                        width="30px"
                        height="30px"
                        :src="IMAGE_API_PATH + questionnaire[6].options.data6.data"
                        fluid
                      />
                      <img
                        width="20px"
                        height="20px"
                        class="img-replace"
                        src="@/assets/icons/replace.png"
                        fluid
                        alt="Replace"
                        @click="replaceQuestionaireQ3Image(index)"
                      />
                      <input
                        v-show="false"
                        ref="questionaireQ3Image"
                        class="choose-file"
                        type="file"
                        @change="chooseQuestionaireQ3Image($event)"
                      />
                    </template>
                  </b-td>
                </b-tr>
                <!--------------------------------------- Question 4---------------------------->
                <b-tr
                  :key="questionnaire[7].id.data + 't345331tquest4r5dr'"
                >
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[7].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td>
                    <div v-if="questionnaire[7].shortText.data.data !== ''">
                      <Editor
                        :key="
                          questionnaire[7].shortText.data.isEdit + '2d1vdquest412f212'
                        "
                        v-model="questionnaire[7].shortText.data.editData"
                        :is-edit="questionnaire[7].shortText.data.isEdit"
                        :current-data="questionnaire[7].shortText.data.data"
                        :validate-max-value="26"
                      />
                    </div>
                  </b-td>
                  <b-td>
                    <Editor
                      :key="
                        questionnaire[7].question.data.isEdit + '2dquest41fd12f212'
                      "
                      v-model="questionnaire[7].question.data.editData"
                      :is-edit="questionnaire[7].question.data.isEdit"
                      :current-data="questionnaire[7].question.data.data"
                      :validate-max-value="124"
                    />
                  </b-td>
                  <b-td style="border: none"></b-td>
                  <b-td>
                    <Editor
                      :key="questionnaire[7].options.data.isEdit + '2d1d12of21quest42'"
                      v-model="questionnaire[7].options.data.editData"
                      :is-edit="questionnaire[7].options.data.isEdit"
                      :current-data="questionnaire[7].options.data.data"
                      :validate-max-value="19"
                    />
                  </b-td>
                </b-tr>
                <b-tr
                  :key="questionnaire[7].id.data + 't345331tr5drrquest4data1'"
                >
                  <b-td style="border: none"></b-td>
                  <b-td>
                    <div v-if="questionnaire[7].shortText.data1.data !== ''">
                      <Editor
                        :key="
                          questionnaire[7].shortText.data1.isEdit + '2d1vd12f212quest4data1'
                        "
                        v-model="questionnaire[7].shortText.data1.editData"
                        :is-edit="questionnaire[7].shortText.data1.isEdit"
                        :current-data="questionnaire[7].shortText.data1.data"
                        :validate-max-value="26"
                      />
                    </div>
                  </b-td>
                  <b-td></b-td>
                  <b-td style="border: none"></b-td>
                  <b-td>
                    <Editor
                      :key="questionnaire[7].options.data1.isEdit + 'quest42d1d12of212data1'"
                      v-model="questionnaire[7].options.data1.editData"
                      :is-edit="questionnaire[7].options.data1.isEdit"
                      :current-data="questionnaire[7].options.data1.data"
                      :validate-max-value="19"
                    />
                  </b-td>
                </b-tr>
                <!--------------------------------------- Question 5 ------------------>
                <b-tr
                  v-for="(value, key, index) in questionnaire[8].options"
                  :key="questionnaire[8].id.data + index + 't345rquest5data1'"
                >
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[8].maxRowLength.data"
                  >
                    <div>
                      <h5 class="color">
                        {{ questionnaire[8].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td v-if="index === 0">
                    <div v-if="questionnaire[8].shortText.data.data !== ''">
                      <Editor
                        :key="questionnaire[8].shortText.data.isEdit +'1quest5data111h2d1d12f212'
                        "
                        v-model="questionnaire[8].shortText.data.editData"
                        :is-edit="questionnaire[8].shortText.data.isEdit"
                        :current-data="questionnaire[8].shortText.data.data"
                        :validate-max-value="26"
                      />
                    </div>
                  </b-td>
                  <b-td v-else />
                  
                  <b-td v-if="index === 0">
                    <Editor
                      :key="
                        questionnaire[8].question.data.isEdit + '2d1dhquest5data1fh12f212'
                      "
                      v-model="questionnaire[8].question.data.editData"
                      :is-edit="questionnaire[8].question.data.isEdit"
                      :current-data="questionnaire[8].question.data.data"
                      :validate-max-value="124"
                    />
                  </b-td>
                  <b-td v-else />
                  
                  <b-td v-if="index === 0">
                    <Editor
                      :key="questionnaire[8].description.data.isEdit +'2d1d12f21quest5data128678'
                      "
                      v-model="questionnaire[8].description.data.editData"
                      :is-edit="questionnaire[8].description.data.isEdit"
                      :current-data="questionnaire[8].description.data.data"
                      :validate-max-value="95"
                    />
                  </b-td>
                  <b-td v-else />
                  
                  <b-td v-if="index !== 2">
                    <Editor
                      :key="value.isEdit + index + '2dquest5data11dddddd12f212'"
                      v-model="value.editData"
                      :is-edit="value.isEdit"
                      :current-data="value.data"
                      :validate-max-value="15"
                    />
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(value, key, index) in questionnaire[8].options"
                  :key="questionnaire[8].id.data + index + 't345rquest5data2'"
                >
                  <b-td style="border: none"></b-td>
                  <b-td style="border: none"></b-td>
                  
                  <b-td v-if="index === 0">
                    <Editor
                      :key="
                        questionnaire[8].question.data1.isEdit + '2d1dhfquest5data2h12f212data1'
                      "
                      v-model="questionnaire[8].question.data1.editData"
                      :is-edit="questionnaire[8].question.data1.isEdit"
                      :current-data="questionnaire[8].question.data1.data"
                      :validate-max-value="230"
                    />
                  </b-td>
                  
                  <b-td style="border: none"></b-td>
                  <b-td v-if="index === 0">
                    <template v-if="!questionnaire[8].question.data1.isEdit">
                      <div
                        :id="`popover-image-${questionaireImageType}-8-${index}`"
                        class="icon-img"
                      >
                        <img
                          width="30px"
                          height="30px"
                          :src="IMAGE_API_PATH + questionnaire[8].options.data2.data"
                          fluid
                          alt="Responsive image"
                        />
                      </div>
                      <ImagePopover
                        :product-type="`${questionaireImageType}-8`"
                        :current-row-index="index"
                        :current-name="questionnaire[8].options.data2.data"
                        :current-image="IMAGE_API_PATH + questionnaire[8].options.data2.data"
                      />
                    </template>
                    <template v-else>
                      <img
                        width="30px"
                        height="30px"
                        :src="IMAGE_API_PATH + questionnaire[8].options.data2.data"
                        fluid
                      />
                      <img
                        width="20px"
                        height="20px"
                        class="img-replace"
                        src="@/assets/icons/replace.png"
                        fluid
                        alt="Replace"
                        @click="replaceQuestionaireQ5Image(index)"
                      />
                      <input
                        v-show="false"
                        ref="questionaireQ5Image"
                        class="choose-file"
                        type="file"
                        @change="chooseQuestionaireQ5Image($event)"
                      />
                    </template>
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(value, key, index) in questionnaire[8].options"
                  :key="questionnaire[8].id.data + index + 't3quest5data245r'"
                >
                  <b-td style="border: none"></b-td>
                  <b-td style="border: none"></b-td>
                  
                  <b-td v-if="index === 0">
                    <Editor
                      :key="
                        questionnaire[8].question.data2.isEdit + '2d1dhfh12f212quest5data3'
                      "
                      v-model="questionnaire[8].question.data2.editData"
                      :is-edit="questionnaire[8].question.data2.isEdit"
                      :current-data="questionnaire[8].question.data2.data"
                      :validate-max-value="230"
                    />
                  </b-td>
                  
                  <b-td style="border: none"></b-td>
                  <b-td style="border: none"></b-td>
                </b-tr>
                <!--------------------------------------- Question 6---------------------------- -->
                <b-tr
                  v-for="(value, key, index) in questionnaire[9].options"
                  :key="questionnaire[9].id.data + index + 'ktrquest6data1we31'"
                >
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[9].maxRowLength.data"
                  >
                    <div>
                      <h5 class="color">
                        {{ questionnaire[9].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td v-if="index === 0">
                    <div v-if="questionnaire[9].shortText.data.data !== ''">
                      <Editor
                        :key="questionnaire[9].shortText.data.isEdit +'2d1fvvquest6data1vvd12f212'
                        "
                        v-model="questionnaire[9].shortText.data.editData"
                        :is-edit="questionnaire[9].shortText.data.isEdit"
                        :current-data="questionnaire[9].shortText.data.data"
                        :validate-max-value="40"
                      />
                    </div>
                  </b-td>
                  <b-td v-else />
                  
                  <b-td v-if="index === 0">
                    <Editor
                      :key="
                        questionnaire[9].question.data.isEdit + '2d1quest6data1dcmjk12f212'
                      "
                      v-model="questionnaire[9].question.data.editData"
                      :is-edit="questionnaire[9].question.data.isEdit"
                      :current-data="questionnaire[9].question.data.data"
                      :validate-max-value="124"
                    />
                  </b-td>
                  <b-td v-else />
                  
                  <b-td />
                  <b-td>
                    <Editor
                      :key="value.isEdit + index + '2d1d12f21nbquest6data1d2'"
                      v-model="value.editData"
                      :is-edit="value.isEdit"
                      :current-data="value.data"
                      :validate-max-value="15"
                    />
                  </b-td>
                </b-tr>
                
                <!--------------------------------------- Question 7 --------------------------->
                <b-tr
                  v-for="(value, key, index) in questionnaire[10].options"
                  :key="questionnaire[10].id.data + index + 'tr122quest7data1e3123'"
                >
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[10].maxRowLength.data"
                  >
                    <div>
                      <h5 class="color">
                        {{ questionnaire[10].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td v-if="index === 0">
                    <Editor
                      :key="questionnaire[10].shortText.data.isEdit + index +'2d1d1ffffffquest7data12f212'
                      "
                      v-model="questionnaire[10].shortText.data.editData"
                      :is-edit="questionnaire[10].shortText.data.isEdit"
                      :current-data="questionnaire[10].shortText.data.data"
                      :validate-max-value="55"
                    />
                  </b-td>
                  <b-td v-else />
                  
                  <b-td v-if="index === 0">
                    <Editor
                      :key="
                        questionnaire[10].question.data.isEdit + index + '2quest7data1mmmd1d12f212'
                      "
                      v-model="questionnaire[10].question.data.editData"
                      :is-edit="questionnaire[10].question.data.isEdit"
                      :current-data="questionnaire[10].question.data.data"
                      :validate-max-value="124"
                    />
                  </b-td>
                  <b-td v-else />
                  
                  <b-td />
                  <b-td>
                    <Editor
                      :key="value.isEdit + index + '2d1d12f21bfbd2quest7data1'"
                      v-model="value.editData"
                      :is-edit="value.isEdit"
                      :current-data="value.data"
                      :validate-max-value="index === 2 ? 15 : 40"
                    />
                  </b-td>
                </b-tr>
                
                <!--------------------------------------- Question 8 ------------------------------>
                <b-tr
                  v-for="(value, key, index) in questionnaire[11].options"
                  :key="questionnaire[11].id.data + index + 'tr122quest8data1'"
                >
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[11].maxRowLength.data"
                  >
                    <div>
                      <h5 class="color">
                        {{ questionnaire[11].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td v-if="index === 0">
                    <Editor
                      :key="
                        questionnaire[11].shortText.data.isEdit + '2quest8data1d1d1ff2f11'
                      "
                      v-model="questionnaire[11].shortText.data.editData"
                      :is-edit="questionnaire[11].shortText.data.isEdit"
                      :current-data="questionnaire[11].shortText.data.data"
                      :validate-max-value="29"
                    />
                  </b-td>
                  <b-td v-else />
                  <b-td v-if="index === 0">
                    <Editor
                      :key="questionnaire[11].question.data.isEdit +'2d1d12fsquest8data1dfsdff212'"
                      v-model="questionnaire[11].question.data.editData"
                      :is-edit="questionnaire[11].question.data.isEdit"
                      :current-data="questionnaire[11].question.data.data"
                      :validate-max-value="124"
                    />
                  </b-td>
                  <b-td v-else />
                  
                  <b-td v-if="index === 0">
                    <Editor
                      :key="questionnaire[11].description.data.isEdit +'2d1dquest8data11dsfsd2f212'"
                      v-model="questionnaire[11].description.data.editData"
                      :is-edit="questionnaire[11].description.data.isEdit"
                      :current-data="questionnaire[11].description.data.data"
                      :validate-max-value="95"
                    />
                  </b-td>
                  <b-td v-else />
                  
                  <b-td v-if="index !== 2">
                    <Editor
                      :key="value.isEdit + index + 'quest8data12d1645645d12f212'"
                      v-model="value.editData"
                      :is-edit="value.isEdit"
                      :current-data="value.data"
                      :validate-max-value="15"
                    />
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(value, key, index) in questionnaire[11].options"
                  :key="questionnaire[11].id.data + index + 'tr122datquest8data2a112'"
                >
                  <b-td style="border: none"></b-td>
                  <b-td style="border: none"></b-td>
                  <b-td v-if="index === 0">
                    <Editor
                      :key="
                        questionnaire[11].question.data1.isEdit +
                          '2d1d12fsdfsdff212quest8data2'
                      "
                      v-model="questionnaire[11].question.data1.editData"
                      :is-edit="questionnaire[11].question.data1.isEdit"
                      :current-data="questionnaire[11].question.data1.data"
                      :validate-max-value="400"
                    />
                  </b-td>
                  <b-td style="border: none"></b-td>
                  <b-td v-if="index === 0">
                    <template v-if="!questionnaire[11].question.data1.isEdit">
                      <div
                        :id="`popover-image-${questionaireImageType}-11-${index}`"
                        class="icon-img"
                      >
                        <img
                          width="30px"
                          height="30px"
                          :src="IMAGE_API_PATH + questionnaire[11].options.data2.data"
                          fluid
                          alt="Responsive image"
                        />
                      </div>
                      <ImagePopover
                        :product-type="`${questionaireImageType}-11`"
                        :current-row-index="index"
                        :current-name="questionnaire[11].options.data2.data"
                        :current-image="IMAGE_API_PATH + questionnaire[11].options.data2.data"
                      />
                    </template>
                    <template v-else>
                      <img
                        width="30px"
                        height="30px"
                        :src="IMAGE_API_PATH + questionnaire[11].options.data2.data"
                        fluid
                      />
                      <img
                        width="20px"
                        height="20px"
                        class="img-replace"
                        src="@/assets/icons/replace.png"
                        fluid
                        alt="Replace"
                        @click="replaceQuestionaireQ8Image(index)"
                      />
                      <input
                        v-show="false"
                        ref="questionaireQ8Image"
                        class="choose-file"
                        type="file"
                        @change="chooseQuestionaireQ8Image($event)"
                      />
                    </template>
                  </b-td>
                </b-tr>
                <!--------------------------------------- Question 9 ------------------------------>
                <b-tr
                  v-for="(value, key, index) in questionnaire[12].description"
                  :key="questionnaire[12].id.data + index + 'tr12quest8data2'"
                >
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[12].maxRowLength.data"
                  >
                    <div>
                      <h5 class="color">
                        {{ questionnaire[12].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  
                  <b-td>
                    <Editor
                      :key="questionnaire[12].shortText[key].data.isEdit +'2dbbquest8data2bbb1d12f212'"
                      v-model="questionnaire[12].shortText[key].editData"
                      :is-edit="questionnaire[12].shortText[key].isEdit"
                      :current-data="questionnaire[12].shortText[key].data"
                      :validate-max-value="28"
                    />
                  </b-td>
                  
                  <b-td v-if="index === 0">
                    <Editor
                      :key="questionnaire[12].question.data.isEdit +'2d1d12fffdsdfdquest8data2sf212'"
                      v-model="questionnaire[12].question.data.editData"
                      :is-edit="questionnaire[12].question.data.isEdit"
                      :current-data="questionnaire[12].question.data.data"
                      :validate-max-value="45"
                    />
                  </b-td>
                  <b-td v-else />
                  
                  <b-td>
                    <div v-if="isObject(value.data)">
                      <Editor
                        :key="value.data.isEdit + '2dfsfd1dquest8data212f212'"
                        v-model="value.data.editData"
                        :is-edit="value.data.isEdit"
                        :current-data="value.data.data"
                        :validate-max-value="60"
                      />
                      <Editor
                        :key="value.data1.isEdit + '2d1dquest8data212fsaf212'"
                        v-model="value.data1.editData"
                        :is-edit="value.data1.isEdit"
                        :current-data="value.data1.data"
                        :validate-max-value="75"
                      />
                    </div>
                    <p v-else>
                      <Editor
                        :key="value.isEdit + index + '2quest8data2dfsdfds1d12f212'"
                        v-model="value.editData"
                        :is-edit="value.isEdit"
                        :current-data="value.data"
                        :validate-max-value="60"
                      />
                    </p>
                  </b-td>
                  <b-td v-if="index === 0">
                    <div style="display: flex; width: 100%">
                      <div class="w-100">
                        <Editor
                          :key="questionnaire[12].options.data.isEdit + '2d1jkgd12fquest8data212'"
                          v-model="questionnaire[12].options.data.editData"
                          :is-edit="questionnaire[12].options.data.isEdit"
                          :current-data="questionnaire[12].options.data.data"
                        />
                      </div>
                      <div class="w-100">
                        <Editor
                          :key="questionnaire[12].options.data1.isEdit +'2d1dffdsquest8data2sdfsd12f212'"
                          v-model="questionnaire[12].options.data1.editData"
                          :is-edit="questionnaire[12].options.data1.isEdit"
                          :current-data="questionnaire[12].options.data1.data"
                          :validate-max-value="2"
                        />
                      </div>
                    </div>
                  </b-td>
                  <b-td v-else />
                </b-tr>
                
                <!--------------------------------------- End survey ------------------------------>
                <b-tr :key="questionnaire[13].id.data + 'tr'">
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[13].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td />
                  <b-td>
                    <Editor
                      :key="questionnaire[13].question.data.isEdit +'2d908endst7i1d12f212'"
                      v-model="questionnaire[13].question.data.editData"
                      :is-edit="false && questionnaire[13].question.data.isEdit"
                      :current-data="questionnaire[13].question.data.data"
                      :validate-max-value="140"
                    />
                    <Editor
                      :key="questionnaire[13].question.data1.isEdit +'2d1d1bere2fendst212'"
                      v-model="questionnaire[13].question.data1.editData"
                      :is-edit="false && questionnaire[13].question.data1.isEdit"
                      :current-data="questionnaire[13].question.data1.data"
                      :validate-max-value="120"
                    />
                  </b-td>
                  <b-td />
                  <b-td />
                </b-tr>
                
                <!--------------------------------------- Button ------------------------------>
                <b-tr :key="questionnaire[14].id.data + 'tr'">
                  <b-td>
                    <div>
                      <h5 class="color">
                        {{ questionnaire[14].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  <b-td />
                  <b-td>
                    <Editor
                      :key="questionnaire[14].question.data.isEdit +'2d1d12dasbuttons2daqw2f212'"
                      v-model="questionnaire[14].question.data.editData"
                      :is-edit="questionnaire[14].question.data.isEdit"
                      :current-data="questionnaire[14].question.data.data"
                      :validate-max-value="15"
                    />
                  </b-td>
                  <b-td />
                  <b-td />
                </b-tr>
                <!--------------------------------------- Message ------------------------------>
                <b-tr
                  v-for="(value, key, index) in questionnaire[15].question"
                  :key="questionnaire[12].id.data + index + '12sf3dmessage23safa'"
                >
                  <b-td
                    v-if="index === 0"
                    :rowspan="questionnaire[15].maxRowLength.data"
                  >
                    <div>
                      <h5 class="color">
                        {{ questionnaire[15].shortName.data.data }}
                      </h5>
                    </div>
                  </b-td>
                  
                  <b-td />
                  <b-td>
                    <div v-if="isObject(value.data)">
                      <Editor
                        :key="value.data.isEdit + '2dssage23safsfd1d12f212'"
                        v-model="value.data.editData"
                        :is-edit="value.data.isEdit"
                        :current-data="value.data.data"
                        :validate-max-value="8"
                      />
                      <Editor
                        :key="value.data1.isEdit + '2d1d12fsaf2ssage23sa12'"
                        v-model="value.data1.editData"
                        :is-edit="value.data1.isEdit"
                        :current-data="value.data1.data"
                        :validate-max-value="125"
                      />
                    </div>
                    <p v-else>
                      <Editor
                        :key="value.isEdit + index + '2dfsdfds1d12f212'"
                        v-model="value.editData"
                        :is-edit="value.isEdit"
                        :current-data="value.data"
                        :validate-max-value="index === 1 ? 125 : 8"
                      />
                    </p>
                  </b-td>
                  <b-td />
                  <b-td v-if="index === 0">
                    <Editor
                      :key="questionnaire[15].options.data.isEdit + '2ssage23sad1jkgd12f212'"
                      v-model="questionnaire[15].options.data.editData"
                      :is-edit="questionnaire[15].options.data.isEdit"
                      :current-data="questionnaire[15].options.data.data"
                      :validate-max-value="15"
                    />
                  </b-td>
                  <b-td v-else>
                    <Editor
                      :key="questionnaire[15].options.data1.isEdit + '2d1jkgd12f2ssage23sa13'"
                      v-model="questionnaire[15].options.data1.editData"
                      :is-edit="questionnaire[15].options.data1.isEdit"
                      :current-data="questionnaire[15].options.data1.data"
                      :validate-max-value="15"
                    />
                  </b-td>
                </b-tr>
                <!--- -->
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--######################### The Modal ERRORR ###########################################################################-->
    <div v-if="showError">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div class="body-text-modal">
                    <p>{{ message }}<br /></p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showError = false"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal ERRORR END ###########################################################################-->
  </div>
</template>

<script>
import { fixHeaderTable, isObject, randomData } from "../../common/CommonUtils";
import { CONTEXT_PATH, LOAD_IMAGE_API_PATH } from "../../constants/Paths";
import UploadFileService from "../../service/UploadFileService";
import ImagePopover from "../common/ImagePopover.vue";
import CheckBoxEditor from "../questionnaire/CheckBoxEditor.vue";
import Editor from "../questionnaire/Editor.vue";
import EditorQn3 from "../questionnaire/EditorQn3.vue";

    const HEADERS_TABLE = [
        { title: "SN", style: "width: 8%", class: "", desc: "" },
        { title: "SHORT TEXT (RX)", style: "width: 12%", class: "", desc: "" },
        { title: "QUESTION", style: "width: 25%", class: "", desc: "" },
        { title: "DESCRIPTION", style: "width: 25%", class: "", desc: "" },
        { title: "OPTIONS", style: "width: 30%", class: "", desc: ".jpeg or .png files only. Max 100KB. Dimension 260 X 260px" },
    ];
    // import $ from "jquery";
    export default {
        components: {
            Editor,
            ImagePopover,
            CheckBoxEditor,
            EditorQn3
        },
        data() {
            this.$store.state.survey.isSurveyEditing = false;
            return {
                showError: false,
                header: HEADERS_TABLE,
                questionType: "h5",
                linkType: "a",
                checkBoxType: "checkbox",
                contentStyle: "color:blue",
                IMAGE_API_PATH: CONTEXT_PATH + LOAD_IMAGE_API_PATH,
                questionaireImageType: "QUESTIONAIRE",
            };
        },
        computed: {
            questionnaire() {
                const data = this.$store.state.questionnaire.questionnaire.questionnaireData;
                // console.log("DATA: " + JSON.stringify(data));
                return data;
            },
            isEditContent() {
                return this.$store.state.questionnaire.questionnaire.editStatus
                    ? "edit-th-content"
                    : "normal-th-content";
            },
        },
        created() {},
        updated() {
            fixHeaderTable(
                "table-1",
                "header-nav",
                this.$store.state.questionnaire.questionnaire.editStatus
            );
        },
        methods: {
            random() {
                return randomData(5, 2);
            },
            isObject(data) {
                return isObject(data);
            },
            transfer(event) {
                this.questionnaire[1].options.data.data = event;
                this.questionnaire[1].options.data.editData = event;
            },
            replaceQuestionaireQ3Image(index) {
                this.changeImageRownIndex = index;
                const elem = this.$refs.questionaireQ3Image[index];
                elem.click();
            },
            chooseQuestionaireQ3Image(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.createQuestionaireQ3Image(files[0], e.target);
            },
            createQuestionaireQ3Image(file, input) {
                let loader = this.$loading.show();
                UploadFileService.checkQuestionaireQ3ImageBeforeUpload(file)
                .then(() => {
                  var suffix = file["type"];
                  if (
                    suffix !== "image/png" &&
                    suffix !== "image/jpeg"
                  ) {
                    console.log(
                      "The image format is incorrect, please upload the file in .png, .jpeg, .gif  format"
                    );
                    this.message =
                      "The image format is incorrect, please upload the file in .png, .jpeg, .gif format";
                      loader.hide();
                    input.value = null;
                    this.showError = true;
                    this.progress = 0;
                    return;
                  }
                  UploadFileService.uploadWithFileNamePrefix(
                    file,
                    "countermat_QuestionaireQ3_image",
                    (event) => {
                        this.progress = Math.round((100 * event.loaded) / event.total);
                    }
                ).then((response) => {
                    loader.hide();
                    var imageName = response.data.name;
                    this.questionnaire[6].options.data6.data = imageName;
                    this.questionnaire[6].options.data6.editData = imageName;
                }).catch((error) => {
                    loader.hide();
                    input.value = null;
                    console.log("error" + error);
                    this.message = "The image size and/or dimension is over the limitation.";
                    this.showError = true;
                    this.progress = 0;
                    console.log(this.message);
                });
                }).catch((e) => {
                  loader.hide();
                    console.log("error" + e);
                    input.value = null;
                    this.message = "The image size and/or dimension is over the limitation.";
                    this.showError = true;
                    this.progress = 0;
                    console.log(this.message);
                })
                
            },

            replaceQuestionaireQ5Image(index) {
                this.changeImageRownIndex = index;
                const elem = this.$refs.questionaireQ5Image[index];
                elem.click();
            },
            chooseQuestionaireQ5Image(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.createQuestionaireQ5Image(files[0], e.target);
            },
            createQuestionaireQ5Image(file, input) {
                let loader = this.$loading.show();
                UploadFileService.checkQuestionaireQ3ImageBeforeUpload(file)
                .then(() => {
                  var suffix = file["type"];
                  if (
                    suffix !== "image/png" &&
                    suffix !== "image/jpeg"
                  ) {
                    console.log(
                      "The image format is incorrect, please upload the file in .png, .jpeg, .gif  format"
                    );
                    this.message =
                      "The image format is incorrect, please upload the file in .png, .jpeg, .gif format";
                      loader.hide();
                    input.value = null;
                    this.showError = true;
                    this.progress = 0;
                    return;
                  }
                  UploadFileService.uploadWithFileNamePrefix(
                    file,
                    "countermat_QuestionaireQ5_image",
                    (event) => {
                        this.progress = Math.round((100 * event.loaded) / event.total);
                    }
                  ).then((response) => {
                      loader.hide();
                      var imageName = response.data.name;
                      console.log("ImageName: " + imageName);
                      this.questionnaire[8].options.data2.data = imageName;
                      this.questionnaire[8].options.data2.editData = imageName;
                  }).catch((error) => {
                      loader.hide();
                      console.log("error" + error);
                      this.message = "The image size and/or dimension is over the limitation.";
                      this.showError = true;
                      this.progress = 0;
                      console.log(this.message);
                      input.value = null;
                  });
                }).catch(() => {
                  loader.hide();
                  this.message = "The image size and/or dimension is over the limitation.";
                  this.showError = true;
                  this.progress = 0;
                  console.log(this.message);
                  input.value = null;
                })
            },

            replaceQuestionaireQ8Image(index) {
                this.changeImageRownIndex = index;
                const elem = this.$refs.questionaireQ8Image[index];
                elem.click();
            },
            chooseQuestionaireQ8Image(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.createQuestionaireQ8Image(files[0], e.target);
            },
            createQuestionaireQ8Image(file, input) {
                let loader = this.$loading.show();
                UploadFileService.checkQuestionaireQ3ImageBeforeUpload(file)
                .then(() => {
                  var suffix = file["type"];
                  if (
                    suffix !== "image/png" &&
                    suffix !== "image/jpeg"
                  ) {
                    console.log(
                      "The image format is incorrect, please upload the file in .png, .jpeg, .gif  format"
                    );
                    this.message =
                      "The image format is incorrect, please upload the file in .png, .jpeg, .gif format";
                      loader.hide();
                    input.value = null;
                    this.showError = true;
                    this.progress = 0;
                    return;
                  }
                  UploadFileService.uploadWithFileNamePrefix(
                      file,
                      "countermat_QuestionaireQ8_image",
                      (event) => {
                          this.progress = Math.round((100 * event.loaded) / event.total);
                      }
                  ).then((response) => {
                      loader.hide();
                      var imageName = response.data.name;
                      console.log("ImageName: " + imageName);
                      this.questionnaire[11].options.data2.data = imageName;
                      this.questionnaire[11].options.data2.editData = imageName;
                  }).catch((error) => {
                      loader.hide();
                      console.log("error" + error);
                      this.message = "The image size and/or dimension is over the limitation.";
                      this.showError = true;
                      this.progress = 0;
                      console.log(this.message);
                      input.value = null;
                  });
                }).catch(() => {
                  loader.hide();
                    this.message = "The image size and/or dimension is over the limitation.";
                    this.showError = true;
                    this.progress = 0;
                    console.log(this.message);
                    input.value = null;
                })
            },
        },
    };
</script>

<style scoped>
  .content {
    padding: 24px;
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch;
  }
  .color {
    color: #006db6;
    font-weight: bold;
    font-size: 15px;
    line-height: 23px;
  }
  .titleDesc {
    font-weight: 300;
  }

  .btn-tooltip {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 71%, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.tooltip-img {
  cursor: pointer;
}
.custom-dialog {
  width: 467px;
  height: 283px;
  background: #4b4b4b;
}

.body-text-modal {
  width: 294px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.body-text-modal-elife-pro-read {
  width: 327px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 25px 0px 35px 0px;
  margin-bottom: 91px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.btn-modal {
  display: flex;
  justify-content: center;
}

.nav-link {
  font-size: 20px;
}
</style>
