<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div>
      <textarea-autosize
        v-if="type !== 'a'"
        v-model="originData"
        :dir="dir"
        :disabled="true"
        :rows="1"
        style="border: none; background: transparent; width: 100%;color: #000"
      />
      <!-- <label v-if="type == 'label'" :style="customStyle"> {{ originData }}</label>
      <h1 v-if="type == 'h1'" :style="customStyle">
        {{ originData }}
      </h1>
      <h2 v-if="type == 'h2'" :style="customStyle">
        {{ originData }}
      </h2>
      <h3 v-if="type == 'h3'" :style="customStyle">
        {{ originData }}
      </h3>
      <h4 v-if="type == 'h4'" :style="customStyle">
        {{ originData }}
      </h4>
      <h5 v-if="type == 'h5'" :style="customStyle">
        {{ originData }}
      </h5>
      <p v-if="type == 'p'" :style="customStyle">
        {{ originData }}
      </p> -->
      <a
        v-if="type == 'a'"
        :href="'https://' + originData"
        :style="cusStyle"
        target="_blank"
      >
        {{ originData }}
      </a>
    </div>
    <div v-if="isEdit">
      <div v-if="editType == 'text'">
        <div class="main">
          <textarea-autosize
            ref="textarea-edit"
            v-model="editValue"
            :dir="dir"
            class="form-control"
            placeholder="Add description"
            :maxlength="validateMaxValue + additionalTextCount"
            @focusout.native="focusOut()"
            @input="handleInput"
          />
          <!-- 
            https://stackoverflow.com/questions/42067208/how-can-i-add-text-to-a-text-area-at-the-bottom-right-of-the-text-area
          -->
          <span>{{ editValue.length - additionalTextCount }}/{{ validateMaxValue }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Editor",
  components: {},
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: [String, Number, Boolean, Object],
      default: "",
    },
    editType: {
      type: String,
      default: "text",
    },
    validateMaxValue: {
      type: Number,
      default: 500,
    },
    type: {
      type: String,
      default: "label",
    },
    cusStyle: {
      type: String,
      default: "color: black;word-break: break-word",
    },
  },
  data() {
    const {code, name} = this.$store.state.survey.language;
    const isArabic = code === "ar" && name === "Arabic";
    return {
      dir: isArabic ? "rtl" : "ltr",
      customStyle: isArabic ? this.cusStyle+";text-align: end;width: 100%" : this.cusStyle+";text-align: start",
      editValue: this.value,
      originData: this.currentData,
    };
  },
  computed: {
    additionalTextCount: {
      get: function () {
        var count = 0;
        const openBracketMatches = this.editValue.match(/{/g);
        if (openBracketMatches !== null) {
          count += openBracketMatches.length;
        }
        const closedBracketMatches = this.editValue.match(/}/g);
        if (closedBracketMatches !== null) {
          count += closedBracketMatches.length;
        }
        return count;
      }
    }
  },
  mounted() {},
  methods: {
    focusOut() {
      if (/[^\s]+/.test(this.editValue)) {
        this.$emit("input", this.editValue);
      } else {
        this.$emit("input", this.originData);
      }
      //  this.$nextTick(() => {});
    },

    handleInput() {
      if (/[^\s]+/.test(this.editValue)) {
        this.$emit("input", this.editValue);
      } else {
        this.$emit("input", this.originData);
      }
      //   this.$nextTick(() => {});
    },

    checkMaxValue($event) {
      if (this.editValue.length > this.validateMaxValue) {
        this.editValue = this.editValue.substring(0, this.validateMaxValue);
        $event.preventDefault();
        return;
      }
    },

    onPaste($event) {
      const value = $event.clipboardData.getData("text");
      if (
        value.length >= this.validateMaxValue ||
        this.editValue.length + value.length >= this.validateMaxValue
      ) {
        this.editValue = this.editValue.substring(0, this.validateMaxValue + 1);
        $event.preventDefault();
        return;
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  background-color: transparent;
  color: #321fdb !important;
}
textarea {
  resize: none;
}

label {
  white-space: pre-wrap;
}

.main {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
  width: 100%;
}

.main textarea {
  border: none!important;
  margin-bottom: 20px;
}

.main textarea:focus, .main textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.main span {
  color: #768192;
  position: absolute;
  bottom: .375rem;
  right: .75rem;
  font-size: 10px;
}

</style>
